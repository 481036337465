<template>
  <div class="row">
    <v-col cols="12" sm="12">
      <b-alert
          show
          variant="light"
          class="alert alert-custom alert-success alert-shadow fade show gutter-b mb-0"
      >
        <div class="alert-text mt-2 text-center">
          <h1 class="black--text"><b>My Preferences</b></h1>
        </div>
      </b-alert>
    </v-col>
    <v-col cols="12" sm="12">
      <v-tabs v-model="activeTab" background-color="primary" dark centered>
        <v-tabs-slider></v-tabs-slider>
        <v-tab>Marketing Preferences</v-tab>
        <v-tab>Contact Preferences</v-tab>
        <v-tab>Areas of Interest</v-tab>

        <v-tab-item>
          <v-card flat>
            <v-card-title>
              You will receive certain communications from us which we deem necessary so that we may fulfill
              obligations
              to you as a member of TCC ADR Centre. These include but are not limited to, notices of AGM’s and other
              material relating to events.
            </v-card-title>
            <v-card-title>
              You may opt out from receiving the following types of communications:
            </v-card-title>
            <v-card-title>
              Offers
            </v-card-title>
            <v-card-title class="m-0">
              <template>
                <v-checkbox
                    class="m-0 blue--text pr-20"
                    v-model="preItem.pre_offers"
                    v-bind:color="preItem.pre_offers === 1 ? 'primary': ''"
                    item-value="value"
                    label="I do not wish to receive offers and exclusive content from TCC ADR Centre and other carefully selected trusted partners that we think you will like. We will never share your personal information with them."
                ></v-checkbox>
              </template>
            </v-card-title>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-title class="m-0">
              <template>
                <v-checkbox
                    class="m-0 blue--text pr-20"
                    v-model="preItem.pre_contact_telephone"
                    v-bind:color="preItem.pre_contact_telephone === 1 ? 'primary': ''"
                    item-value="value"
                    label="Do you consent to TCC ADR Centre contacting you through telephone?"
                ></v-checkbox>
              </template>
              <template>
                <v-checkbox
                    class="m-0 blue--text pr-20"
                    v-model="preItem.pre_contact_post"
                    v-bind:color="preItem.pre_contact_post === 1 ? 'primary': ''"
                    item-value="value"
                    label="Do you consent to TCC ADR Centre contacting you through post?"
                ></v-checkbox>
              </template>
              <template>
                <v-checkbox
                    class="m-0 blue--text pr-20"
                    v-model="preItem.pre_contact_email"
                    v-bind:color="preItem.pre_contact_email === 1 ? 'primary': ''"
                    item-value="value"
                    label="Do you consent to TCC ADR Centre sending you relevant information by email?"
                ></v-checkbox>
              </template>
              <template>
                <v-checkbox
                    class="m-0 blue--text pr-20"
                    v-model="preItem.pre_contact_sms"
                    v-bind:color="preItem.pre_contact_sms === 1 ? 'primary': ''"
                    item-value="value"
                    label="Do you consent to TCC ADR Centre sending you relevant information by SMS?"
                ></v-checkbox>
              </template>
            </v-card-title>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-title class="m-0">
              <!--              <template>-->
              <!--                <v-checkbox-->
              <!--                    class="m-0 blue&#45;&#45;text pr-20"-->
              <!--                    v-model="preItem.pre_aoi_all"-->
              <!--                    v-bind:color="preItem.pre_aoi_all === 1 ? 'primary': ''"-->
              <!--                    item-value="value"-->
              <!--                    label="All Categories"-->
              <!--                ></v-checkbox>-->
              <!--              </template>-->
              <template>
                <v-checkbox
                    class="m-0 blue--text pr-20"
                    v-model="preItem.pre_aoi_adjudication"
                    v-bind:color="preItem.pre_aoi_adjudication === 1 ? 'primary': ''"
                    item-value="value"
                    label="Adjudication"
                ></v-checkbox>
              </template>
              <template>
                <v-checkbox
                    class="m-0 blue--text pr-20"
                    v-model="preItem.pre_aoi_arbitration"
                    v-bind:color="preItem.pre_aoi_arbitration === 1 ? 'primary': ''"
                    item-value="value"
                    label="Arbitration"
                ></v-checkbox>
              </template>
              <template>
                <v-checkbox
                    class="m-0 blue--text pr-20"
                    v-model="preItem.pre_aoi_mediation"
                    v-bind:color="preItem.pre_aoi_mediation === 1 ? 'primary': ''"
                    item-value="value"
                    label="Mediation"
                ></v-checkbox>
              </template>
              <template>
                <v-checkbox
                    class="m-0 blue--text pr-20"
                    v-model="preItem.pre_aoi_conciliation"
                    v-bind:color="preItem.pre_aoi_conciliation === 1 ? 'primary': ''"
                    item-value="value"
                    label="Conciliation"
                ></v-checkbox>
              </template>
              <template>
                <v-checkbox
                    class="m-0 blue--text pr-20"
                    v-model="preItem.pre_aoi_expert_determination"
                    v-bind:color="preItem.pre_aoi_expert_determination === 1 ? 'primary': ''"
                    item-value="value"
                    label="Expert Determination"
                ></v-checkbox>
              </template>
              <template>
                <v-checkbox
                    class="m-0 blue--text pr-20"
                    v-model="preItem.pre_aoi_neutral_evaluation"
                    v-bind:color="preItem.pre_aoi_neutral_evaluation === 1 ? 'primary': ''"
                    item-value="value"
                    label="Neutral Evaluation"
                ></v-checkbox>
              </template>
              <template>
                <v-checkbox
                    class="m-0 blue--text pr-20"
                    v-model="preItem.pre_aoi_dispute_boards"
                    v-bind:color="preItem.pre_aoi_dispute_boards === 1 ? 'primary': ''"
                    item-value="value"
                    label="Dispute Boards"
                ></v-checkbox>
              </template>
              <template>
                <v-checkbox
                    class="m-0 blue--text pr-20"
                    v-model="preItem.pre_aoi_pro_bono"
                    v-bind:color="preItem.pre_aoi_pro_bono === 1 ? 'primary': ''"
                    item-value="value"
                    label="Pro Bono"
                ></v-checkbox>
              </template>
            </v-card-title>
          </v-card>
        </v-tab-item>
      </v-tabs>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="btn btn-primary" dark text @click="saveItem(preItem)">
          Update
        </v-btn>
      </v-card-actions>
      <v-col cols="12">
        <div v-if="errors!=undefined && errors.length >= 0"
             role="alert"
             v-bind:class="{ show: errors.length }"
             class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </v-col>
    </v-col>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {CLEAR_USER_ERRORS, UPDATE_USER_PREFS} from "@/core/services/store/users.module";
import Swal from "sweetalert2";

export default {
  name: "preferences",
  data() {
    return {
      preItem: this.$store.state.auth.user
    }
  },
  components: {},
  mounted() {
  },
  methods: {
    saveItem(item) {
      this.$store.dispatch(CLEAR_USER_ERRORS);
      this.$store.dispatch(UPDATE_USER_PREFS, {'slug': item.id, data: item}).then((data) => {
        Swal.fire('', data._metadata.message, 'success').then(() => {
          window.location.reload();
        })
      })
    }
  },
  computed: {
    ...mapState({
      errors: state => state.users.errors
    })
  }
};
</script>
